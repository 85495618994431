import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharingService} from '../services/sharing.service';
import {Member, MemberService} from '../services/member.service';

import jsonBlogs from '../../assets/config/blogs.json';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit, OnDestroy {

  private routeSub: any;
  private routeId: string;

  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  blogEntries: Array<Blog> = [];
  sortedBlogEntries: Array<Blog> = [];
  openedBlogArticle:
    {title: string, date: Date, authorList: Array<string>, md: string, categories: Array<string>, active?: boolean, id: string,
    mailString?: string, twitterString?: string};
  openedBlogArticleAuthors: Array<Member>;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public memberService: MemberService) { }

  ngOnInit() {
    for (const blog of jsonBlogs) {
      this.blogEntries.push(new Blog(blog.title, blog.date, blog.authorList, blog.md, blog.categories, blog.id));
    }
    this.sortedBlogEntries = this.blogEntries.sort((a: any, b: any) =>
      // Descending Date
      b.date.getTime() - a.date.getTime()
    );

    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.routeId = params.id;
      if (!this.routeId) {
        this.router.navigate(['/blog', this.sortedBlogEntries[0].id]).then(() => {
          this.openBlogArticle(this.routeId);
        });
      } else {
        this.openBlogArticle(this.routeId);
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  private openBlogArticle(id: string) {
    for (const sortedBlogEntry of this.sortedBlogEntries) {
      if (sortedBlogEntry.id === id) {
        this.openedBlogArticle = sortedBlogEntry;
        this.openedBlogArticle.mailString = SharingService.createMailString(this.getShareLink(), this.openedBlogArticle.title);
        this.openedBlogArticle.twitterString = SharingService.createTwitterString(
          this.getShareLink(), this.openedBlogArticle.title, this.openedBlogArticle.categories);

        this.openedBlogArticleAuthors = [];
        for (const author of this.openedBlogArticle.authorList) {
          const fullMember: Member = this.memberService.getMember(author);
          if (fullMember) {
            this.openedBlogArticleAuthors.push(fullMember);
          }
        }

        sortedBlogEntry.active = true;
      } else {
        sortedBlogEntry.active = false;
      }
    }
  }

  private getShareLink() {
    const origin = window.location.origin;
    const pageRoute = this.router.url;
    return (origin + pageRoute);
  }

  blogDateFormat(date: Date) {
    return this.monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' +  date.getFullYear();
  }

}

class Blog {
  public readonly title: string;
  public readonly date: Date;
  public readonly authorList: Array<string>;
  public readonly md: string;
  public readonly categories: Array<string>;
  public readonly id: string;
  public active?: boolean;

  constructor(title: string, date: string, authorList: Array<string>, md: string, categories: Array<string>, id: string) {
    this.title = title;
    this.date = new Date(date);
    this.authorList = authorList;
    this.md = md;
    this.categories = categories;
    this.id = id;
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// Components
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {OverviewComponent} from './overview/overview.component';
import {BlogComponent} from './blog/blog.component';
import {NotFoundComponent} from './not-found/not-found.component';

// Third Party
import {MarkdownModule} from 'ngx-markdown';
import {CookieService} from 'ngx-cookie-service';

// Services
import {GaService} from './services/ga.service';
import {MemberService} from './services/member.service';
import {SharingService} from './services/sharing.service';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    BlogComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    HttpClientModule,
    MarkdownModule.forRoot({loader: HttpClient}),
  ],
  providers: [
    GaService,
    MemberService,
    SharingService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

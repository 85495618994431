import {Injectable, ComponentFactoryResolver, Inject, ReflectiveInjector, Component} from '@angular/core';
import UIkit from 'uikit';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MemberService {

  public currentMember: Member;
  private MEMBER_LIST: Array<Member> = [
    new Member('Fabian Kohler',
      'Fabian',
      'Co-Founder',
      ['BackendDevelopment', 'Databases', 'AppDevelopment'],
      '/assets/images/members/fabian.jpg',
      ''),
    new Member('Dominik Müller',
      'Dominik',
      'Co-Founder',
      ['MachineLearning', 'Visualization', 'WebAndAppDev'],
      '/assets/images/members/dominik.jpg',
      'Passionate web and app developer. Stepped into the fields of machine learning and deep learning. ' +
      'Driven by the fusion of UX and ML.'),
    new Member('David Wild',
      'David',
      'Co-Founder',
      ['ProductManagement', 'UX', 'IdeationInnovation'],
      '/assets/images/members/david.jpg',
      ''),
  ];

  constructor(private sanitizer: DomSanitizer) { }

  get memberList(): Array<Member> {
    return this.MEMBER_LIST;
  }

  getMember(name: string): Member {
    for (const member of this.MEMBER_LIST) {
      if (member.name === name) {
        return member;
      }
    }
    return null;
  }

  setCurrentMember(name: string) {
    for (const member of this.MEMBER_LIST) {
      if (member.name === name) {
        this.currentMember = member;
      }
    }
  }
}

export class Member {
  public readonly name: string;
  public readonly shortName: string;
  public readonly role: string;
  public readonly tags: Array<string>;
  public readonly image: string;
  public readonly about: string;

  constructor(name: string, shortName: string, role: string, tags: Array<string>, image: string, about: string) {
    this.name = name;
    this.shortName = shortName;
    this.role = role;
    this.tags = tags;
    this.image = image;
    this.about = about;
  }
}

import {Component} from '@angular/core';
import {GaService} from './services/ga.service';
import {CookieService} from 'ngx-cookie-service';
import {MemberService} from './services/member.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'binaerburg-web';

  constructor(private gaService: GaService,
              private cookieService: CookieService,
              public memberService: MemberService) {
  }

  /**
   * Tracking cookies (GA) are only distributed when consenting
   */
  acceptCookies() {
    this.gaService.loadGA();
    this.gaService.sendPageView('');
  }

  /**
   * Check if cookie exist
   */
  cookieExist(): boolean {
    return this.cookieService.check('_ga');
  }
}

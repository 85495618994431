import {Injectable} from '@angular/core';

declare let ga: any;

@Injectable({
  providedIn: 'root'
})
export class GaService {
  trackingID = 'UA-135985596-1';

  constructor() {
  }

  loadGA() {
    // @ts-ignore
    ga('create', this.trackingID, 'auto');
  }

  /**
   * sends the page view to GA.
   */
  sendPageView(page: string) {
    // @ts-ignore
    ga('send', 'pageview', page);
  }
}


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  static createMailString(link: string, title: string) {
    const header = 'mailto:?subject=' + title;
    const body = '&body=Check out the blog entry on ' + link + '.';
    return (header + body);
  }

  static createTwitterString(link: string, title: string, hashtags: Array<string>) {
    const tweetBase = 'https://twitter.com/intent/tweet';
    const tweetText = '?text=' + title + ' - Check out this blog on';
    const tweetLink = '&url=' + link + '\n';
    const tweetHashTags = '&hashtags=' + hashtags.toString();
    return (tweetBase + tweetText + tweetLink + tweetHashTags);
  }
}

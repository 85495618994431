import {Component, OnInit} from '@angular/core';
import {Member, MemberService} from '../services/member.service';
import {MarkdownService} from 'ngx-markdown';

import jsonProjects from '../../assets/config/projects.json';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  memberList: Array<Member>;
  projectList: Array<Project> = [];

  constructor(public memberService: MemberService,
              private markdownService: MarkdownService) { }

  ngOnInit() {
    this.memberList = this.memberService.memberList;
    for (const project of jsonProjects) {
      this.markdownService.getSource(project.md).toPromise().then(text => {
        this.projectList.push(new Project(project.name, project.company, project.companylogo, project.companyurl,
          project.date, project.md, this.lessText(50, text)));
      });
    }
  }

  lessText(wordCount: number, text: string) {
    return text.split(' ').slice(0, wordCount).join(' ') + '...';
  }

}

class Project {
  public readonly name: string;
  public readonly company: string;
  public readonly companylogo: string;
  public readonly companyurl: string;
  public readonly date: string;
  public readonly md: string;
  public short?: string;

  constructor(name: string, company: string, companylogo: string, companyurl: string, date: string, md: string, short: string) {
    this.name = name;
    this.company = company;
    this.companylogo = companylogo;
    this.companyurl = companyurl;
    this.date = date;
    this.md = md;
    this.short = short;
  }
}
